import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Container, Row, Col, Card, CardBody, Form, Button, CardFooter } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import * as dates from 'date-arithmetic';

import env from '../../env/src_config';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { isNull, displayDate } from '../../izUtils';
import { transformer, linker } from '../../helpers/fields';
import DisplayFields from '../../helpers/displayFields';
import { headersState, tokenState, multiSelectLoading, serviceDraft } from '../../recoil/recoil';
import { errorStatus, axiosError } from '../../helpers/response';
import { getSingleItem } from '../../helpers/getSingleItem';
import TaskTechnicians from '../../components/task/list/TaskTechnicians';
import CreateService from '../../components/service/CreateService';
import ListService from '../../components/service/ListService';
import FileUpload from '../../components/fileUpload/FileUpload';
import Spinner from '../../components/spinner/Spinner';
import TaskPrices from '../../components/task/taskPrices/TaskPrices';
import { changeStatus } from '../../helpers/changeStatus';
import { saveService } from '../../helpers/saveService';
import { saveTaskPrices } from '../../helpers/saveTaskPrices';
import { saveInvoiceReceipt } from '../../helpers/saveInvoiceReceipt';
import InventoryReceipts from '../../components/InventoryReceipt/InventoryReceipts';
import Loader from '../../components/spinner/Loader';

const CreateTask = () => {
    const {t} = useTranslation();
    const Navigate = useNavigate();
    const {id, method} = useParams();

    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const setServiceDraftData = useSetRecoilState(serviceDraft);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [statusBtn, setStatusBtn] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    const taskPricesRef = useRef();
    const taskData = useRef({});

    const lsTaskinputs = 'AKODA.taskInputs';
    const lsTechicians = 'AKODA.taskTechnicians';
    const lsTaskFiles = 'AKODA.taskFiles-'+id;
    const lsTaskClientFiles = 'AKODA.taskClientFiles';
    const lsTaskQuotations = 'AKODA.taskQuotations';
    const lsTaskFacilityFiles = 'AKODA.taskFacilityFiles';

    useEffect(() => {
        if (id !== 'create') {
            changeStatus(false, null, id, headers, Navigate).then(data => {
                setStatusBtn(data)
            })
        }

        if (id === 'create' || (!isNull(method) && method === 'update')) {
            localStorage.removeItem(lsTaskFiles)
            updateData(false, null, false, false, false);
        } else {
            getSingleItem(headers, id, '/api/task/', Navigate).then(data => {
                if (!isNull(data)) {
                    if (data.message === 'Dostop zavrnjen') {
                        Navigate('/')
                    } else  {
                        taskData.current = data.otherData;
                        localStorage.setItem(lsTaskinputs, JSON.stringify(data.fields));
                        setInputs(data.fields);
                    }
                }
            })
        }

        return () => {
            localStorage.removeItem(lsTaskinputs)
            localStorage.removeItem(lsTechicians)
            localStorage.removeItem(lsTaskFiles)
            localStorage.removeItem(lsTaskClientFiles)
            localStorage.removeItem(lsTaskQuotations)
            localStorage.removeItem(lsTaskFacilityFiles)
        }
    }, [id, method]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = async (save, passedInputs, status = false, closeAfter, serviceSave) => {
        let payload = { save };
        let proceed = true;

        // Check for assigned_tasks AND/OR assigned_events and show warning
        if (save && (status === 'in_progress') && (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator')) {
            let errorTxt = '';
            passedInputs.technicians.values.forEach(val => {
                if (passedInputs.technicians.value.indexOf(val.id) !== -1) {
                    if (!isNull(val)) {
                        if ((!isNull(val.tasks) && val.tasks.length !== 0) || (!isNull(val.events) && val.events.length !== 0)) {
                            errorTxt += '<br /><b>' + val.title + '</b>: '
                        }

                        if (!isNull(val.tasks) && val.tasks.length !== 0) {
                            errorTxt += '<br />' + t('tasks') + ': '
                            val.tasks.forEach(task => {
                                errorTxt += `<br /><a href="/task/${task.id}" target="_blank")>${task.client.client}, ${task.facility.name} (${displayDate(moment.utc(task.service_from)) + ' - ' + displayDate(moment.utc(task.service_to))})</a><br />`
                            })
                        }

                        if (!isNull(val.events) && val.events.length !== 0) {
                            errorTxt += '<br />' + t('events') + ': '
                            val.events.forEach(event => {
                                errorTxt += `<br /><a href="/schedule/events/${event.id}/get" target="_blank")>${event.client.client}, ${event.facility.name} (${displayDate(moment.utc(event.from)) + ' - ' + displayDate(moment.utc(event.to))})</a><br />`
                            })
                        }
                    }
                }
            })

            if (errorTxt.length !== 0) {
                await Swal.fire({
                    title: t('task.assignedTasks.title'),
                    html: '<div style="text-align: start">' + errorTxt + '</div>',
                    icon: 'warning',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('proceed'),
                    showCancelButton: true,
                    cancelButtonText: t('close'),
                }).then((val) => {
                    if (!val.isConfirmed) {
                        proceed = false;
                    }
                })
            }
        }

        if (proceed) {
            if (save) {
                setDisableBtn(true);
                setLoading(true);
            }

            if (!isNull(passedInputs)) {
                // Get data from inputs
                const keys = Object.keys(passedInputs);
                payload.data = {};
                const toBool = ['important'];
                for (let i = 0; i < keys.length; i++) {
                    if (toBool.indexOf([keys[i]]) !== -1) {
                        if (isNull(passedInputs[keys[i]].value)) payload.data[keys[i]] = false
                    }
                    if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                        payload.data[keys[i]] = passedInputs[keys[i]].value;
                    } else {
                        payload.data[keys[i]] = "";
                    }
                }

                // Get data from localStorage
                let selectedTechnicians = JSON.parse(localStorage.getItem(lsTechicians))
                if (!isNull(selectedTechnicians)) {
                    if (isNull(payload.data)) payload.data = {};
                    payload.data.technicians = selectedTechnicians.map(technician => technician/*.id*/)
                }

                let taskFiles = JSON.parse(localStorage.getItem(lsTaskFiles))
                if (!isNull(taskFiles)) {
                    if (isNull(payload.data)) payload.data = {};
                    payload.data.files = taskFiles.map(file => file.id);
                }

                let taskQuotations = JSON.parse(localStorage.getItem(lsTaskQuotations))
                if (!isNull(taskQuotations)) {
                    if (isNull(payload.data)) payload.data = {};
                    payload.data.quotations = taskQuotations.map(file => file.id);
                }
            }

            if (isNull(payload.data)) payload.data = {};
            payload.data.time_adjustment = ((new Date().getTimezoneOffset() / 60)*-1);

            let method = "patch";
            if (id === 'create') method = "post";
            axios[method](env.api + '/api/task/' + id, payload, {headers}).then(async response => {
                if (save) {
                    if (!isNull(response.data.state) && response.data.state === 'success') {
                        let proceed = true;

                        // Save task prices
                        if ((!isNull(taskData.current.permissions)) && (taskData.current.permissions['prices.update'])) {
                            const taskPricesInputs = taskPricesRef.current.getTaskPricesInputs(); // Get inputs from task prices form
                            await saveTaskPrices(headers, id, taskPricesInputs).then(data => {
                                if (isNull(data.state) || data.state !== 'success') {
                                    proceed = false;
                                    errorStatus(data, t);
                                }
                            })
                        }

                        // Loop and save invoice receipt (break the loop and show error if save is not valid)
                        if ((!isNull(taskData.current.permissions)) && (taskData.current.permissions['inventory_receipt.update'])) {
                            for (let i = 0; i < taskData.current.inventory_receipts.length; i++) {
                                let proceedLoop = true;
                                // eslint-disable-next-line
                                await saveInvoiceReceipt(headers, taskData.current.inventory_receipts[i].id, i).then(data => {
                                    if (isNull(data.state) || data.state !== 'success') {
                                        proceed = false;
                                        proceedLoop = false;
                                        errorStatus(data, t);
                                    }
                                })

                                if (!proceedLoop) {
                                    break;
                                }
                            }
                        }

                        if (proceed) {
                            if (status) { // If change status button was clicked
                                if (serviceSave) { // If service needs to be saved
                                    const serviceInputs = JSON.parse(localStorage.getItem('AKODA.serviceInputs'))
                                    await saveService(headers, id, serviceInputs, 'AKODA.serviceTechnicians', true, false).then(data => {
                                        if (data.valid) {
                                            changeStatus(true, status, id ,headers, Navigate)
                                        } else {
                                            errorStatus(data, t);
                                        }
                                    })
                                } else {
                                    await changeStatus(true, status, id ,headers, Navigate)
                                }
                            } else {
                                if (serviceSave) { // If service needs to be saved
                                    const serviceInputs = JSON.parse(localStorage.getItem('AKODA.serviceInputs'))
                                    await saveService(headers, id, serviceInputs, 'AKODA.serviceTechnicians', true, false).then(data => {
                                        if (data.valid) {
                                            Swal.fire({
                                                title: t('saved'),
                                                text: data.message,
                                                icon: 'success',
                                                confirmButtonColor: 'var(--theme-default)',
                                                confirmButtonText: t('ok'),
                                            }).then(() => Navigate('/'))
                                        } else {
                                            errorStatus(data, t);
                                        }
                                    })
                                } else {
                                    Swal.fire({
                                        title: t('saved'),
                                        text: response.data.message,
                                        icon: 'success',
                                        confirmButtonColor: 'var(--theme-default)',
                                        confirmButtonText: t('ok'),
                                    }).then(() => {
                                        if(closeAfter)  {
                                            Navigate('/tasks')
                                        } else {
                                            if (id === 'create') {
                                                Navigate('/task/' + response.data.id + '/update')
                                            } else {
                                                changeStatus(false, null, id ,headers, Navigate).then(data => {
                                                    setStatusBtn(data)
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    } else {
                        errorStatus(response.data, t);
                        setShowErrors(true);
                    }

                    setDisableBtn(false);
                    setLoading(false);
                } else {
                    if (response.data.status === 'error' && response.data.code === 'task_locked') {
                        Navigate('/task/'+ id +'/get')
                    }

                    if (!isNull(response.data.services)) {
                        const draftData = response.data.services.find(service => service.draft) // If task has a draft-service, save is to global state
                        if (!isNull(draftData)) {
                            setServiceDraftData({
                                isDraft: true,
                                serviceId: draftData.id,
                                values: null
                            })
                        } else {
                            setServiceDraftData({
                                isDraft: false,
                                serviceId: null,
                                values: null,
                            })
                        }
                    }
                }

                taskData.current = response.data;
                setMultiLoading(false) // Set loading for field MultiSelect to false
                if (!isNull(response.data.data)) {
                    let transformedResponse = transformer(response.data.data)
                    setInputs(transformedResponse);
                    localStorage.setItem(lsTaskinputs, JSON.stringify(transformedResponse));
                }
            }).catch(error => {
                axiosError(error, Navigate);
                if (error.response.data.message === "Dostop zavrnjen") Navigate('/')
            });
        }
    }

    const handleStatusButton = async (status) => {
        let proceed = true;
        if ( (dates.lte(moment.utc(inputs.service_from.value).format(), moment.utc(new Date()).format(), 'minutes')) && (taskData.current.task_status === 'preview') && (status === 'in_progress') ) {
            await Swal.fire({
                title: t('task.serviceFromInPast'),
                icon: 'warning',
                confirmButtonColor: '#var(--theme-default)',
                confirmButtonText: t('proceed'),
                showCancelButton: true,
                cancelButtonText: t('cancel'),
            }).then(val => {
                if (!val.isConfirmed) proceed = false;
            })
        }

        if (proceed) {
            if (tokenData.parsedToken.type === 'technician' && taskData.current.task_status === 'in_progress' && status === 'checkup') {
                // Ask technician if he wants to save the last service
                Swal.fire({
                    title: t('service.saveLastService'),
                    icon: 'question',
                    confirmButtonColor: '#var(--theme-default)',
                    confirmButtonText: t('service.saveWithLast'),
                    showDenyButton: true,
                    showCancelButton: true,
                    denyButtonText: t('service.saveWithoutLast'),
                    cancelButtonText: t('cancel'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        updateData(true, inputs, status, true, true) // First save task then service and then change task status
                    } else if (result.isDenied) {
                        updateData(true, inputs, status, true, false) // First save task and then change task status
                    }
                })
            } else {
                if (taskData.current.permissions['task.update'] && status !== 'canceled') {
                    updateData(true, inputs, status, true, false)
                } else {
                    setDisableBtn(true);
                    setLoading(true)

                    changeStatus(true, status, id ,headers, Navigate).then(() => {
                        setDisableBtn(false);
                        setLoading(false);
                    })
                }
            }
        }
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (name === 'client' || name === 'facility') {
            clonedInputs['security_systems'].value = "";
            if (name === 'client') clonedInputs['facility'].value = "";
        } else if (name === 'service_from') {
            clonedInputs['service_to'].value = value;
        }
        updateData(false, clonedInputs, false, false, false);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};
        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }
        updateData(false, clonedInputs, false, false, false);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            component: {id: 'createTask'},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            multiHandler: (value, id, component) => multiChange(value, id, component),
            dateHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        if (method === 'get') {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            if (!isNull(inputs[field]) && inputs[field].disabled) {
                return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
            } else {
                return linker(inputlinkerFields);
            }
        }
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        // Status and save buttons
        let statusButtonsStart = []
        let statusButtonsEnd = []
        let statusButtonsStartTechnician = [];
        let statusButtonsEndTechnician = [];

        statusBtn.forEach(button => {
            let buttonTitle = button;
            if (tokenData.parsedToken.type === 'technician') {
                if ( (taskData.current.task_status === 'in_progress') && (button === 'preview') ) {
                    statusButtonsStartTechnician.push(
                        <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    )
                } else if ( (taskData.current.task_status === 'in_progress') && (button === 'checkup') ) {
                    statusButtonsEnd.push(
                        <Button key={'btn-0-'+button} color="success" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false, true, true)}>
                            {t(`service.save`)}
                        </Button>,
                        <Button key={'btn-1-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    );
                } else if ( (taskData.current.task_status === 'preview') && (button === 'reported') ) {
                    statusButtonsStart.push(
                        <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    );
                } else {
                    statusButtonsEnd.push(
                        <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    );
                }
            } else if (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') {
                if (
                    ((taskData.current.task_status === 'checkup') && (button === 'in_progress')) ||
                    ((taskData.current.task_status === 'in_progress') && (button === 'preview')) ||
                    (button === 'canceled')
                ) {
                    statusButtonsStart.push(
                        <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    );
                } else {
                    if (taskData.current.task_status === 'checkup' && button === 'approved') buttonTitle = taskData.current.task_status+'_'+button
                    statusButtonsEnd.push(
                        <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                            {t(`task.updateStatus.${tokenData.parsedToken.type}.${buttonTitle}`)}
                        </Button>
                    );
                }
            } else {
                statusButtonsEnd.push(
                    <Button key={'btn-'+button} color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => handleStatusButton(button)}>
                        {t(`task.updateStatus.${buttonTitle}`)}
                    </Button>
                );
            }
        })

        let showSaveButtons = true;
        if (( (!isNull(method)) && (method === 'update') && (!isNull(taskData.current.permissions)) && (taskData.current.permissions['task.update']) )) {
            if ( (tokenData.parsedToken.type === 'technician') && (taskData.current.task_status === 'in_progress') ) {
                showSaveButtons = false
                if (statusBtn.indexOf('preview') === -1) {
                    statusButtonsEndTechnician.push(
                        <Button key="save_technician" color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false, false, false)}>{t('save')}</Button>
                    )
                }
            } else if ( (tokenData.parsedToken.type === 'accountant') && (taskData.current.task_status === 'approved') ) {
                showSaveButtons = false
            } else if ( (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') && (taskData.current.task_status === 'checkup') ) {
                showSaveButtons = false;
                statusButtonsStart.push(
                    <Button key="save_and_close" color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false, true, false)}>{t('saveAndClose')}</Button>
                )
            }
        }
        // End status and save buttons

        return (
            <Fragment>
                <Breadcrumbs parent={taskData.current.task_status === 'archive' ? t('breadcrumb.archive') : t('breadcrumb.kanban')} title={t(`status.${taskData.current.task_status}`)} />
                <Container fluid={true}>
                    {loading && <Loader position="fixed" />}
                    <Row>
                        <Col sm="12">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    {!isNull(taskData.current.locked) &&
                                        <div className='locked-bar'>
                                            <span><i className="icon-lock bigger-icon"></i></span>
                                            <span>
                                                {t('lockedBy')}:&nbsp;
                                                {taskData.current.locked.locked_by.name}
                                            </span>
                                        </div>
                                    }
                                    <div className="ribbon ribbon-clip ribbon-primary">
                                        {(method === 'get')
                                            ? t('task.get.title')
                                            : (id === 'create' ? t('task.create.title') : t('task.update.title'))
                                        }
                                    </div>
                                    <Form className="theme-form">
                                        <Row>
                                            <Col md="6">
                                                { ['submitted_by', 'submitter_name', 'submitter_email', 'submitter_phone', 'submitted_at', 'client', 'facility', 'facility_additional_notes'].map(field => mapLinker(field)) }
                                                <FileUpload data={inputs.attachments} saveToLS={lsTaskFacilityFiles} inputType="createTaskFacility" />
                                                { [ 'security_systems', 'contract_number', 'response_time', 'intervention_time', 'error_correction_time', /*'response_rate', */'important', 'admin_interaction', 'client_report', 'security_system_report', 'client_contact_name_report', 'client_contact_phone_report', 'error_description'].map(field => mapLinker(field)) }
                                            </Col>
                                            <Col md="6">
                                                { ['service_from', 'service_to', 'work_estimate', 'service_type', 'service_payment_type', 'project_number'].map(field => mapLinker(field)) }
                                                {((inputs.technicians.display) && (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator')) &&
                                                    <Link to="/schedule/users" target="_blank" rel="noopener noreferrer">{t('task.schedule')}</Link>
                                                }
                                                <TaskTechnicians data={inputs.technicians} saveToLS={lsTechicians} showErrors={showErrors} updateData={() => updateData(false, JSON.parse(localStorage.getItem(lsTaskinputs)), false, false, false)}/>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md="6">
                                                { ['client_notes', 'administrator_notes', 'material_notes', 'technician_notes', 'technician_message', 'reject_message'].map(field => mapLinker(field)) }
                                            </Col>
                                            <Col md="6">
                                                <FileUpload data={inputs.files} saveToLS={lsTaskFiles} inputType="createTask" />
                                                { ( !isNull(inputs.files.value) && inputs.files.value.length !== 0 && inputs.files.display && !isNull(inputs.client_files.value) && inputs.client_files.value.length !== 0 && inputs.client_files.display ) && <hr/> }
                                                <FileUpload data={inputs.client_files} saveToLS={lsTaskClientFiles} inputType="createTaskClient" />
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                {( ( !isNull(statusButtonsStartTechnician) && statusButtonsStartTechnician.length !== 0 ) || ( !isNull(statusButtonsEndTechnician) && (statusButtonsEndTechnician.length !== 0) ) ) &&
                                    <CardFooter>
                                        <div className='d-flex justify-content-between'>
                                            {statusButtonsStartTechnician}
                                            {statusButtonsEndTechnician}
                                        </div>
                                    </CardFooter>
                                }
                            </Card>
                        </Col>
                    </Row>
                    {/* INVENTORY RECEIPT */}
                    {( (!isNull(taskData.current.inventory_receipts) && taskData.current.inventory_receipts.length !== 0) || (!isNull(taskData.current.permissions) && taskData.current.permissions['inventory_receipt.create']) ) &&
                        <InventoryReceipts taskData={taskData.current}/>
                    }
                    {/* SERVICES */}
                    {( (!isNull(taskData.current.services)) && (taskData.current.services.length !== 0) ) &&
                        <Row>
                            <Col sm="12">
                                <ListService
                                    services={taskData.current.services}
                                    permissions={taskData.current.permissions}
                                    type="private"
                                    getPriceData={() => {
                                        const taskPricesInputs = taskPricesRef.current.getTaskPricesInputs();
                                        taskPricesRef.current.updatePrices(false, taskPricesInputs, false);
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                    {(!isNull(taskData.current.permissions) && taskData.current.permissions['service.create']) &&
                        <Row>
                            <Col sm="12">
                                <CreateService />
                            </Col>
                        </Row>
                    }
                    {/* QUOTATIONS */}
                    { ( (!isNull(inputs.quotations)) && (inputs.quotations.display) && ( (method === 'update') || ( (isNull(method) || method === 'get') && (!isNull(inputs.quotations.value)) && (inputs.quotations.value.length !== 0) ) ) ) &&
                        <Col sm="12">
                            <Card>
                                <div className='p-3'>
                                    <FileUpload data={inputs.quotations} saveToLS={lsTaskQuotations} inputType="createTaskQuotations" />
                                </div>
                            </Card>
                        </Col>
                    }
                    {/* PRICES */}
                    {( ( (!isNull(taskData.current.permissions)) && ( (taskData.current.permissions['prices.update']) || (taskData.current.permissions['prices.view']) ) ) ) &&
                        <Row>
                            <Col sm="12">
                                <TaskPrices taskData={taskData.current} type="private" ref={taskPricesRef} />
                            </Col>
                        </Row>
                    }

                    { (inputs?.invoice_number?.display && !isNull(inputs?.invoice_number?.value) && inputs?.invoice_number?.value.length !== 0 ) &&
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <Form className="theme-form">
                                            <Row>
                                                <Col md="6">
                                                    { ['invoice_number'].map(field => mapLinker(field)) }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(taskData.current.task_status === 'archive' ? '/archive' : '/')}>{t('back')}</Button>
                            {statusButtonsStartTechnician.length === 0 && statusButtonsStart}
                        </div>
                        <div>
                            { ( (id === 'create') || ( (!isNull(method)) && (method === 'update') && (!isNull(taskData.current.permissions)) && (taskData.current.permissions['task.update']) && showSaveButtons ) ) &&
                                <Fragment>
                                    {id !== 'create' && <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false, false, false)}>{t('save')}</Button>}
                                    <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false, true, false)}>{t('saveAndClose')}</Button>
                                </Fragment>
                            }
                            {statusButtonsEnd}
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default CreateTask