import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter, Collapse } from 'reactstrap'
import Swal from 'sweetalert2'
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import FacilitiesComponent from './facilities/FacilitiesComponent'

const AddUpdateClient = ({getClients}) => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { clientId } = useParams()

    const [isOpen, setIsOpen] = useState(null);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);
        updateData(false, null);

    }, [clientId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = "patch";
        if (clientId === 'create') method = "post";
        axios[method](env.api + '/api/client/' + clientId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    getClients(); // Refresh client list
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => Navigate('/clients/' + response.data.id))
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Fragment>
            <Card className="ribbon-wrapper">
                <div className="ribbon ribbon-clip ribbon-primary">{(clientId === 'create') ? t('clients.create.title') : t('clients.update.title')}</div>
                {(clientId === 'create') ?
                    <>
                        <CardBody>
                            {isNull(inputs) ?
                                <Spinner />
                                :
                                <Fragment>
                                    <Form className="theme-form mb-5">
                                        { ['client', 'location', 'country', 'company_code', 'tax_number', 'invoice_recipient'].map(field => mapLinker(field)) }
                                    </Form>
                                </Fragment>
                            }
                        </CardBody>
                        <CardFooter>
                            <div className='text-end'>
                                <Button color="primary" onClick={() => updateData(true, inputs)}>{(clientId === 'create') ? t('add') : t('save')}</Button>
                            </div>
                        </CardFooter>
                    </>
                    :
                    <>
                        <Accordion>
                            <div className='p-3'>
                                <Button color="secondary" className="card-collapse-button" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                    <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                        <i className="fa fa-chevron-down"></i>
                                    </div>
                                </Button>
                            </div>
                            <Collapse isOpen={isOpen}>
                                <CardBody>
                                    {isNull(inputs) ?
                                        <Spinner />
                                        :
                                        <Fragment>
                                            <Form className="theme-form mb-5">
                                                { ['client', 'location', 'country', 'company_code', 'tax_number', 'invoice_recipient'].map(field => mapLinker(field)) }
                                            </Form>
                                        </Fragment>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <div className='text-end'>
                                        <Button color="primary" onClick={() => updateData(true, inputs)}>{(clientId === 'create') ? t('add') : t('save')}</Button>
                                    </div>
                                </CardFooter>
                            </Collapse>
                        </Accordion>
                    </>
                }
            </Card>
            {clientId !== 'create' && <FacilitiesComponent />}
        </Fragment>
    )
}

export default AddUpdateClient