import React, {Fragment, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, CardBody, CardHeader, Button, Card, Modal, CardFooter } from 'reactstrap';

import { isNull } from '../../izUtils';
import InventoryReceiptItem from './list/InventoryReceiptItem';
import ModalCreateInventoryReceipt from './modal/ModalCreateInventoryReceipt';

const InventoryReceipt = ({taskData}) => {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false)
    const modaltoggle = () => setModalOpen(val => !val)

    const addInventoryReceiptId = (data) => {
        taskData.inventory_receipts.push({id: data.id})
    }

    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader>
                            <h5>{t('inventoryReceipt.title')}</h5>
                        </CardHeader>
                        <CardBody>
                            {(!isNull(taskData.inventory_receipts) && taskData.inventory_receipts.length !== 0) &&
                                <div>
                                    {taskData.inventory_receipts.map((inventoryReceipt, index) => (
                                       <InventoryReceiptItem
                                           key={isNull(inventoryReceipt.id) ? 'inventory-receipt-item-'+index : inventoryReceipt.id}
                                           inventoryReceipt={inventoryReceipt}
                                           index={index}
                                           permissions={taskData.permissions}
                                       />
                                    ))}
                                </div>
                            }
                        </CardBody>
                        {(!isNull(taskData.permissions) && taskData.permissions['inventory_receipt.create']) &&
                            <CardFooter>
                                <div className='text-end'>
                                    <Button color="primary" className="me-2" onClick={modaltoggle}>{t('inventoryReceipt.add')}</Button>
                                </div>
                            </CardFooter>
                        }
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={modalOpen} toggle={modaltoggle} centered size="xl">
                <ModalCreateInventoryReceipt modaltoggle={modaltoggle} addInventoryReceiptId={addInventoryReceiptId} taskData={taskData} />
            </Modal>
        </Fragment>
    )
}

export default InventoryReceipt